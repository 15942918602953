import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutPageComponent } from './about/about-page.component';
import { SharedModule } from '../shared/shared.module';
import { ResourceCenterRoutingModule } from './resourcecenter-routing.module';

@NgModule({
  declarations: [AboutPageComponent],
  imports: [CommonModule, SharedModule, ResourceCenterRoutingModule],
})
export class ResourceCenterModule {}
