import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FDN, VALIDATORS, FormButton, Field, Option } from '@intersystems/isc-form';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { IConfig } from 'src/app/core/interfaces/config';
import { EditFormService } from '../../edit-form.service';

@Component({
  selector: 'app-adapter',
  templateUrl: './adapter.component.html',
  styleUrls: ['./adapter.component.scss'],
})
export class AdapterComponent implements OnChanges, OnInit, OnDestroy {
  @Input() formModel: Partial<IConfig> = {};
  @Input() metadata!: any;
  @Input() adapterType!: string;
  @Input() mode: 'edit' | 'view' = 'edit';
  @Input() isConfigExist: boolean;

  @Output() nextClick = new EventEmitter<void>();
  @Output() previousClick = new EventEmitter<void>();

  FDN: FDN = null;

  adaptersList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private editFormService: EditFormService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getAdaptersList();
    if (changes.adapterType) this.editFormService.changeAdapterClass();
  }

  ngOnInit(): void {
    this.editFormService.adapter.subscribe(() => this.setFDN());
  }

  setFDN(): void {
    this.FDN = {
      name: '',
      description: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: true, showSectionCount: false },
      sections: [
        {
          fields: [
            {
              id: 'adapter',
              key: this.adapterType + '_adapter',
              type: 'select',
              className: 'adapter-type',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Adapter is a required field',
              },
              templateOptions: {
                label: this.adapterType.charAt(0).toUpperCase() + this.adapterType.slice(1) + ' Adapter',
                asyncOptions: () => this.adaptersList$,
                required: true,
              },
              data: {
                displayField: 'name',
                uniqueValueField: 'value',
                onlySaveUniqueValueField: true,
                optionIdField: 'value',
              },
              wrappers: ['select-wrapper'],
              hooks: {
                onInit: field =>
                  field.formControl.valueChanges
                    .pipe(
                      tap(() => this.editFormService.changeAdapterClass()),
                      takeUntil(this._unsubscribeAll),
                    )
                    .subscribe(),
              },
            },
          ],
        },
        {
          name: 'Adapter Configuration',
          fields: [{ key: this.adapterType + '_config', fieldGroup: [] }],
        },
      ],
    };
    if (this.formModel[this.adapterType + '_adapter'])
      this.changeAdapterClass(this.formModel[this.adapterType + '_adapter']);
  }

  buttons: FormButton[] = [
    {
      id: 'btn-next',
      text: 'Previous',
      buttonClass: 'secondary',
      type: 'button',
      callback: (_event, _button, formModel) => {
        this.previousClick.emit(formModel);
      },
    },
    {
      id: 'btn-next',
      text: 'Next',
      buttonClass: 'primary',
      type: 'submit',
      callback: (_event, _button, formModel) => {
        this.nextClick.emit(formModel);
      },
      disabledIfFormInvalid: true,
    },
  ];

  getAdaptersList(): any {
    const options = this.metadata[this.adapterType + '_adapters'].map(adapter => ({
      name: adapter.id,
      value: adapter.id,
    }));
    return this.adaptersList$.next(options);
  }

  changeAdapterClass(newAdapter: string): void {
    const adapter = this.metadata[this.adapterType + '_adapters'].find(el => el.id == newAdapter);
    const fields = adapter.properties.map(property => {
      let field: Partial<Field> = {
        id: property.name,
        key: property.name,
        type: this.setFieldType(property.name, property.type),
        templateOptions: {
          label: this.underscoreToCamelcase(property.name),
        },
        data: { hint: property.description },
      };

      if (property.required) {
        field.overrideValidatorMessage = {
          [VALIDATORS.ISC_REQUIRED]: 'This is a required field',
        };
        field.templateOptions.required = true;
      }
      if (property.type.toLowerCase().includes('integer' || 'numeric' || 'float')) {
        field.type = 'input';
        field.templateOptions.pattern = '[0-9]+';
        field.overrideValidatorMessage = {
          ...field.overrideValidatorMessage,
          [VALIDATORS.PATTERN]: 'Field must be a numeric',
        };
        field.templateOptions.pattern = '[0-9]+';
        field.parsers = [value => parseInt(value)];
      }

      if (property.name.toLowerCase().includes('credential')) {
        field = {
          ...field,
          type: 'select',
          templateOptions: {
            ...field.templateOptions,
            options: this.getCredentialsList(),
            unsetModelWhenOptionsUpdated: true,
          },
          data: {
            displayField: 'name',
            uniqueValueField: 'value',
            onlySaveUniqueValueField: true,
            optionIdField: 'value',
            hint: property.description,
          },
          wrappers: ['select-wrapper'],
        };
      }

      // file_url property is non editable for existing records
      if (property.name == 'file_url' && this.isConfigExist) {
        field.viewOnly = true;
      }

      return field;
    });

    this.FDN = { ...this.FDN };
    this.FDN.sections[1].fields[0].fieldGroup = fields;
  }

  setFieldType(name: string, type: string): string {
    // TODO: need to follow up regarding 'HS.BulkFHIR.Auth.LocalOAuth.ClientConfig'
    if (type.toLowerCase().includes('string') || type === 'HS.BulkFHIR.Auth.LocalOAuth.ClientConfig') {
      return 'input';
    }

    if (type.toLowerCase().includes('boolean')) {
      if (typeof this.formModel[this.adapterType + '_config'][name] == 'undefined')
        this.formModel[this.adapterType + '_config'][name] = false;
      return 'checkbox';
    }
  }

  getCredentialsList(): Option[] {
    return [{}].concat(
      this.metadata.credentials.map(credential => ({
        name: credential.id,
        value: credential.id,
      })),
    );
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  underscoreToCamelcase(underscoreString: string): string {
    let upper = underscoreString.replace(/(\_\w)/g, m => ' ' + m[1].toUpperCase());
    upper = upper.charAt(0).toUpperCase() + upper.slice(1);
    return upper;
  }
}
