<fr-topbar #topbar [appNameConfig]="appNameConfig" [headerConfig]="headerConfig" [menusConfig]="menusConfig">
  <div class="main">
    <ng-progress></ng-progress>
    <lib-spinner></lib-spinner>
    <fr-notification></fr-notification>

    <div class="columns">
      <div class="left-column">
        <fr-left-menu #leftMenu [config]="treeConfig">
        </fr-left-menu>
      </div>
      <div class="right-column" [ngClass.xs]="['right-column-mobile']">
        <app-breadcrumbs>
        </app-breadcrumbs>
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</fr-topbar>