import { Component, EventEmitter, Output } from '@angular/core';
import { FDN } from '@intersystems/isc-form';
import { ILogFilter } from 'src/app/core/interfaces/dataLogs';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Output() applyFilter = new EventEmitter<ILogFilter>();

  filter: any = { _component: 'bfc', _endpoint: '' };
  FDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'lastModified',
            key: '_last_modified_from',
            type: 'date-picker',
            templateOptions: {
              label: 'Last Modified From',
            },
          },
          {
            id: 'lastModified',
            key: '_last_modified_to',
            type: 'date-picker',
            templateOptions: {
              label: 'Last Modified To',
            },
          },
          {
            id: 'component',
            key: '_component',
            type: 'select',
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
            },
            templateOptions: {
              label: 'Component',
              options: [
                {},
                {
                  name: 'bfc',
                  value: 'bfc',
                },
                {
                  name: 'fetch',
                  value: 'fetch',
                },
                {
                  name: 'auth',
                  value: 'auth',
                },
                {
                  name: 'storage',
                  value: 'storage',
                },
              ],
            },
          },
        ],
      },
    ],
  };
}
