<div class="view-export">
  <a class="view-export__btn-return" mat-raised-button (click)="goToConfigurations.emit()">
    Server Configurations</a>
  <div class="view-export-box">
    <div class="view-export-box__features">
      <h2 class="view-export-box__title" *ngIf="configName">{{configName}}</h2>
      <h2 *ngIf="!configName">Loading...</h2>
    </div>
    <div class="view-export-box__features__menu">
      <div class="list-actions btn" color="primary">
        <div class="mat-icons mat-icons__copy" (click)="onCopyConfig()" data-title="Copy">
          <svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 24 24" width="30px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
              fill="white" />
          </svg>
        </div>
        <div class="mat-icons mat-icons__edit" (click)="onEditConfig()" data-title="Edit">
          <svg width="30" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24.4573 5.60156L21.1829 8.87697L24.9256 12.6188L28.2001 9.3453L24.4573 5.60156ZM8.652 21.4098L12.3948 25.1525L23.9899 13.5545L20.2462 9.81169L8.652 21.4098ZM5.80005 27.9811L11.4132 26.1107L7.67143 22.3679L5.80005 27.9811Z"
              fill="white" />
          </svg>
        </div>
        <div class="mat-icons mat-icons__download" (click)="onDownloadConfig()" data-title="Download">
          <svg width="30" height="25" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z"
              fill="white" />
          </svg>
        </div>
        <div class="mat-icons mat-icons__delete" (click)="onDeleteConfig()" data-title="Delete">
          <svg width="30" height="25" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.2001 8.6002V22.6002H5.00005V8.6002H16.2001ZM14.1001 0.200195H7.10005L5.70005 1.6002H0.800049V4.4002H20.4001V1.6002H15.5001L14.1001 0.200195ZM19.0001 5.8002H2.20005V22.6002C2.20005 24.1402 3.46005 25.4002 5.00005 25.4002H16.2001C17.7401 25.4002 19.0001 24.1402 19.0001 22.6002V5.8002Z"
              fill="white" />
          </svg>
        </div>
      </div>
      <a mat-flat-button class="fr-primary mat-icons__plus" color="primary" (click)="createClick.emit()">
        <mat-icon>add</mat-icon>
        New Export Request
      </a>
    </div>
  </div>
  <!-- <div class="view-export-box__size">
        <i>Space Available: {{spaceAvaliable}} Mb</i>
    </div> -->
  <fr-table Id="access-logs-table" [config]="tableConfigNoComplete" [paginatorConfig]="paginatorConfigNoComplete"
    [data]="dataNoComplete">
    <div class="custom-table-header-space" table-header-space>
    </div>
  </fr-table>
  <div class="table-sub-header">
    <app-loading-spinner *ngIf="!dataNoComplete"></app-loading-spinner>
  </div>
  <fr-table Id="access-logs-table" [config]="tableConfigComplete" [paginatorConfig]="paginatorConfigComplete"
    [data]="dataComplete">
    <div class="custom-table-header-space" table-header-space></div>
  </fr-table>
  <div class="table-sub-header">
    <app-loading-spinner *ngIf="!dataComplete"></app-loading-spinner>
  </div>