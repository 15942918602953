import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigsService } from '../../services/configs.service';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SessionsService } from '../../services/sessions.service';
import { SharedService } from '../../shared/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-export-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss'],
})
export class CreatePageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  configs$: Observable<any>;
  configs: any;
  skipFirstStep = false;

  endpointUrl: string;

  formModel: any = {
    endpoint_url: null,
    type: {
      name: null,
      value: null,
    },
    since: null,
  };

  constructor(
    private configsService: ConfigsService,
    private sessionsService: SessionsService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.configs$ = this.configsService.getConfigs().pipe(
      map(configsData => {
        this.configs = configsData.configs;
        if (this.route.snapshot.queryParamMap.get('copy')) {
          this.initExportData();
        } else if (this.route.snapshot.queryParamMap.get('configName')) {
          this.initExportConfigName();
          this.skipFirstStep = true;
        }
        return configsData.configs;
      }),
    );
  }

  initExportData(): void {
    const exportSettingsData = JSON.parse(this.route.snapshot.queryParamMap.get('data'));
    this.endpointUrl = this.configs.find(config => config.name === exportSettingsData.configName).endpoint_url;

    this.formModel = {
      ...this.formModel,
      endpoint_url: this.endpointUrl,
      type: {
        name: exportSettingsData.type,
        value: exportSettingsData.type,
      },
    };

    if (exportSettingsData.type === 'Group')
      this.formModel = {
        ...this.formModel,
        group_id: exportSettingsData.group_id,
      };
  }

  initExportConfigName(): void {
    const exportDataConfigName = this.route.snapshot.queryParamMap.get('configName');
    this.endpointUrl = this.configs.find(config => config.name === exportDataConfigName).endpoint_url;
    this.formModel = {
      ...this.formModel,
      endpoint_url: this.endpointUrl,
    };
  }

  createExport(data): void {
    data.type = data.type.value;
    this.sessionsService
      .postSession(data)
      .pipe(
        switchMap(() => this.configsService.getConfigs()),
        tap(() => this.sharedService.showSuccess('Successfully created export')),
        tap(() => this.sharedService.emitRefresh()),
        tap(() => this.router.navigate(['/exports'])),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
