import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  DropdownData,
  FlatNode,
  HeaderConfig,
  LeftMenuComponent,
  LeftMenuConfig,
  MenusConfig,
  TopbarComponent,
  TopbarControlService,
  HeaderConfigService,
} from '@intersystems/header';
import { NotificationService } from '@intersystems/notification';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TableConfigService } from '@intersystems/table';
import { ConfirmationDialogConfigService } from '@intersystems/confirmation-dialog';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from './shared/services/shared.service';
import { MainMenuService } from './core/main-menu.service';
import { IscFormConfigService } from '@intersystems/isc-form';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private topbarControlService: TopbarControlService,
    private headerConfigService: HeaderConfigService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private tableConfigService: TableConfigService,
    private formConfigService: IscFormConfigService,
    private confirmationDialogConfigService: ConfirmationDialogConfigService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private mainMenuService: MainMenuService,
    private location: Location,
    private httpClient: HttpClient,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data),
      )
      .subscribe((event: { [name: string]: any }) => {
        if (event['openUrl']) {
          window.open(event['openUrl']);
          this.location.back();
        }
      });
  }

  @ViewChild('topbar', { static: true } as any) topbar: TopbarComponent;
  @ViewChild('leftMenu') leftMenu: LeftMenuComponent;

  userMenuData$ = new BehaviorSubject<DropdownData[]>([]);
  activeAppId$ = new BehaviorSubject<string>('');
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  currentTenantId$: Observable<string>;
  userTenants: any;
  userTenantsOptions = [];

  treeConfig: LeftMenuConfig = {
    activeNodeId: this.activeAppId$,
    data: this.mainMenuService.menuData$,
    onItemClick: node => this.onMenuSelect(node),
    expanded: true,
  };

  appNameConfig = {
    appName: 'Bulk FHIR',
    useOnMobile: false,
  };

  usernameMenu: DropdownData[] = [
    {
      id: 'user-back-to-mp',
      name: 'Management Portal',
    },
    {
      id: 'user-documentation',
      name: 'Documentation',
    },
    {
      id: 'user-logout',
      name: 'Logout',
    },
  ];

  headerConfig: HeaderConfig = {
    companyLogo: {
      link: '/',
      src: 'assets/images/InterSystemsIcon.svg',
      altText: 'Home',
    },
    showSpacerOnMobile: true,
    useDivider: true,
    flexLayoutAlign: 'start center',
    flexLayoutAlignMobile: 'start center',
    useBackButton: false,
  };

  menusConfig: MenusConfig = {
    hamburgerMenu: {
      useOnDesktop: false,
      useOnMobile: true,
      data: this.mainMenuService.menuData$,
      activeNodeId: this.activeAppId$,
    },
    avatarMenu: {
      useOnMobile: true,
    },
    dropdown: {
      data: this.userMenuData$,
    },
    productLogo: {
      src: 'assets/images/Logo.svg',
      altText: 'InterSystems Cloud Portal',
    },
    onItemClick: node => this.onMenuSelect(node),
  };

  ngOnInit() {
    this.httpClient
      .get<any>(environment.API_URL + '/sessiondata')
      .pipe(tap(sessionData => this.updateTopBar({ username: sessionData.username })))
      .subscribe();

    const navigationEvents$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => this.activateMenu(e.urlAfterRedirects)),
      takeUntil(this._unsubscribeAll),
    );
    navigationEvents$.subscribe();

    // Initialise translation service
    this.headerConfigService.configureTranslation();
    this.tableConfigService.configureTranslation();
    this.formConfigService.configureTranslation();
    this.confirmationDialogConfigService.configureTranslation();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  updateTopBar(user) {
    if (user) {
      this.topbarControlService.setUsernameData({
        userName: user['username'],
        textAboveUserName: 'User:',
        hideOnMobile: false,
      });
      this.userMenuData$.next(this.usernameMenu);
    } else {
      this.topbarControlService.setUsernameData({});
      this.userMenuData$.next([]);
    }
  }

  onLogout() {
    this.topbar.showDropdown = false;
    this.httpClient
      .get<any>(environment.API_URL + '/sessiondata/logout')
      .pipe(tap(() => window.location.reload()))
      .subscribe();
  }

  onMenuSelect(item: FlatNode | DropdownData) {
    const itemArray = item.id.split('-');
    if (itemArray[0] == 'user') return this.onUserMenuSelect(item); //User menu

    if (itemArray.length == 3) {
      this.router.navigate([itemArray[1], itemArray[2]]);
    } else {
      this.router.navigate([itemArray[1]]);
    }
  }

  onUserMenuSelect(item: DropdownData) {
    if (item.id == 'user-logout') return this.onLogout();
    if (item.id == 'user-documentation')
      return window.open('https://docs.intersystems.com/irisforhealthlatest/csp/docbook/DocBook.UI.Page.cls', '_blank');
    if (item.id == 'user-back-to-mp') location.href = '/csp/sys/UtilHome.csp';
    return false;
  }

  activateMenu(url) {
    const urlArray = url.split('/');
    let activeId = '';
    if (urlArray.length == 4) activeId = 'nav-' + urlArray[1] + '-' + urlArray[3];
    else if (urlArray.length == 3) activeId = 'nav-' + urlArray[1] + '-' + urlArray[2];
    else activeId = 'nav-' + urlArray[1];
    this.activeAppId$.next(activeId);
  }
}
