import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-exported-files-table',
  templateUrl: './exported-files-table.component.html',
  styleUrls: ['./exported-files-table.component.scss'],
})
export class ExportedFilesTableComponent {
  @Input() sessionData: any = null;

  @Output() download: EventEmitter<any> = new EventEmitter<any>();

  tableConfig: TableConfig = {
    key: 'exported-files-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No exported files available',
    stickyHeaderRow: true,
    useSearch: true,
    header: {
      title: 'Exported Files',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      SharedUtils.getTableColumn('type', 'Type'),
      SharedUtils.getTableColumn('url', 'Name', row => row.url.split('/').pop()),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['download'],
            download: {
              id: 'download',
              tooltip: { text: 'Download' },
              customSvgIcon: 'custom-download',
              callback: (clickEvent, row) => this.download.emit({ name: row.type, url: row.url }),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    length: this.sessionData?.json?.output.length,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
