import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FDN, FormButton } from '@intersystems/isc-form';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-config-delete',
  templateUrl: './config-delete.component.html',
})
export class ConfigDeleteComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ConfigDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  FDN: FDN = {
    name: 'Delete Config',
    description: `All data and settings of this config will be permanently deleted.
                      You can't undo this action. Are you sure you want to delete the following config?`,
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'endpoint_url',
            key: 'endpoint_url',
            type: 'input',
            viewOnly: true,
            templateOptions: {
              label: 'Config endpoint_url:',
            },
            data: {},
          },
          {
            id: 'confirmation',
            key: 'confirm',
            type: 'input',
            templateOptions: {
              label: 'Type "' + this.data.endpoint_url + '" in the box below to delete config (case-sensitive)',
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      text: 'Delete',
      buttonClass: 'primary',
      id: 'delete',
      type: 'submit',
      disabled: (_formModel: any, _formOptions: any, form: any) =>
        form.valueChanges.pipe(map((formModel: any) => formModel.confirm != this.data.endpoint_url)),
      callback: () => this.dialogRef.close(true),
    },
    {
      text: 'Cancel',
      buttonClass: 'secondary',
      id: 'cancel',
      type: 'submit',
      callback: () => this.dialogRef.close(false),
    },
  ];

  ngOnInit(): void {
    // remove extra spaces
  }
}
