<h1>InterSystems FHIR Server</h1>

<h2>For Seamless Data Flow</h2>

<p>The InterSystems FHIR Server helps health organizations realize the key advantage of FHIR
    interoperability – seamless health data flow across organizations and IT systems – faster. The benefits include cost
    reductions, improved patient engagement, enhanced medication management, reduced medical errors, improved
    utilization, and better health outcomes.</p>

<p>FHIR Server is a fully managed, enterprise-grade FHIR server providing an easy, secure, and scalable
    repository for storing and sharing healthcare data. It’s built on InterSystems IRIS data platform, known for its
    high performance, scalability, and reliability.</p>

<p>More than 1 billion health records worldwide and 2 of the 3 major EHR products that Gartner calls "Global
    Solutions" run on InterSystems technology.</p>

<h2>FHIR Server Features</h2>

FHIR Server includes:

<ul>
    <li>Support for the latest HL7 FHIR version R4, including the United States Core Data for Interoperability (USCDI)
    <li> A <a href="https://community.intersystems.com/" target="_blank">Developer Portal</a> for understanding and
        testing FHIR APIs, using a Swagger-UI front-end, and easy access to
        all FHIR search parameters and documentation</li>
    <li>Multiple methods of authentication, including an API Key and several Identity Providers (IdPs) using OpenID
        Connect
    <li>Built-in support for OAuth 2.0 using the Cognito authorization server, including intuitive methods to select
        custom FHIR scopes
    <li>Support for multiple external OAuth 2.0 servers (OKTA, Ping, Google Identity, Azure AD)
    <li>Batch import of FHIR bundles via Secure File Transfer Protocol (SFTP) and HTTPS
    <li>Logging of important FHIR request data
    <li>Exceptional high availability using mirroring
    <li>Continuous updates and new features delivered automatically
</ul>

<h2>Certified Security Supporting HIPAA and GDPR</h2>

The service is built on AWS’ ISO 27001:2013 and HITRUST certified infrastructure to support HIPAA and GDPR and is
managed by InterSystems Managed Services through processes developed using the HITRUST CSF.

<h2>Rapid Implementation, High Performance, High Availability</h2>

InterSystems customers can implement a new enterprise-grade FHIR service at the click of a button and select among
several server sizes that can process more than 50,000 requests per minute. In addition, customers can select a
high-availability option for mission-critical services and applications using mirroring and fast failover.

<h2>Unleash the Data</h2>

The FHIR Server enables you to quickly connect with any data source that supports FHIR. It is simple to
store data from the Internet of Medical Things (IoMT), electronic health records, and research databases. Customers
can
visualize an entire ecosystem of health-related data working to promote better health outcomes. For those requiring
a
robust enterprise-grade server, the FHIR Server provides the necessary tools to unleash your data.