import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

type Breadcrumb = { caption?: string; url?: Array<UrlSegment[]> };

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private sharedService: SharedService) {}

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private routeChange$: Observable<Event>;
  breadcrumbs: Breadcrumb[] = [];

  ngOnInit(): void {
    const navigationEndEvents$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.routeChange$ = navigationEndEvents$.pipe(
      tap(() => this.updateBreadcrumbs()),
      takeUntil(this._unsubscribeAll),
    );
    this.routeChange$.subscribe();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateBreadcrumbs() {
    this.breadcrumbs = [];
    let url = [];
    let currentPart = this.activatedRoute;
    while (currentPart) {
      if (currentPart.snapshot?.data?.caption && currentPart.snapshot?.url[0] !== undefined) {
        url = url.concat(currentPart.snapshot.url.map(segment => segment.path));
        this.breadcrumbs.push({ caption: currentPart.snapshot.data.caption, url: [...url] });
      }
      currentPart = currentPart.firstChild;
    }
  }

  onRefresh() {
    this.sharedService.reloadCurrentRoute();
  }
}
