import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { IConfig } from 'src/app/core/interfaces/config';
import { ISessionData } from 'src/app/core/interfaces/sessions';
import { ConfigDeleteComponent } from 'src/app/configs/dialogs/config-delete/config-delete.component';
import { ConfigsService } from 'src/app/services/configs.service';
import { SessionsService } from 'src/app/services/sessions.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss'],
})
export class ViewPageComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private sessionService: SessionsService,
    private sharedService: SharedService,
    private configsService: ConfigsService,
    private dialog: MatDialog,
  ) {}

  private listSessions: ISessionData[];

  private configs$: Observable<IConfig[]>;

  public currentConfig$: Observable<IConfig>;

  public exportsComplete$: Observable<ISessionData[]>;

  public exportsNoComplete$: Observable<ISessionData[]>;

  public configName: string;

  public configEnpointURL: string;

  public configSize: string;

  ngOnInit(): void {
    this.configName = this.route.snapshot.paramMap.get('configName');

    this.configs$ = this.configsService.getConfigs().pipe(map(data => data.configs));

    this.currentConfig$ = this.configs$.pipe(map(data => data.find(config => config.name === this.configName)));

    this.sessionService.getSessions({}).subscribe(data => {
      this.listSessions = data.sessions;

      this.exportsComplete$ = this.sessionService
        .getSessions({})
        .pipe(
          map(data =>
            data.sessions.filter(
              session =>
                session.config.name == this.configName &&
                (session.status == 'complete' || session.status == 'terminated'),
            ),
          ),
        );

      this.exportsNoComplete$ = this.sessionService
        .getSessions({})
        .pipe(
          map(data =>
            data.sessions.filter(
              session =>
                session.config.name == this.configName &&
                session.status != 'complete' &&
                session.status != 'terminated',
            ),
          ),
        );
    });
  }

  createClick(): void {
    this.router.navigate(['/exports/create/'], {
      queryParams: { configName: this.configName },
      relativeTo: this.route,
    });
  }

  goToConfigurations(): void {
    this.router.navigate(['/configs/list'], { relativeTo: this.route });
  }

  findSessionStatus(sessionId: string, listSessions: ISessionData[]) {
    return listSessions.find(el => el.sessionId === sessionId).status;
  }

  cancelExport(sessionId: string): any {
    const currentStatus = this.findSessionStatus(sessionId, this.listSessions);
    if (currentStatus == 'in-progress' || currentStatus == 'pending') {
      this.sessionService.deleteSession(sessionId).subscribe(data => {
        this.notificationService.showSuccess('Export cancelled', 2000);
        this.sharedService.reloadCurrentRoute();
        return data;
      });
    } else this.notificationService.showAlert('Export was already completed or terminated', 2000);
  }

  downloadExport(sessionId: string): void {
    const request_SessionApplication = this.listSessions.find(
      el => el.sessionId == sessionId,
    ).request_SessionApplication;
    console.log(request_SessionApplication);
    this.router.navigate(['/exports/' + sessionId], {
      queryParams: { endpoint_url: request_SessionApplication },
      relativeTo: this.route,
    });
  }

  progressExport(sessionId: string): any {
    const currentStatus = this.findSessionStatus(sessionId, this.listSessions);
    if (currentStatus == 'in-progress') {
      this.sessionService.turnPauseSession(sessionId).subscribe(data => {
        this.notificationService.showSuccess('Export paused', 2000);
        this.sharedService.reloadCurrentRoute();
        return data;
      });
    } else if (currentStatus == 'pending') {
      this.sessionService.turnResumeSession(sessionId).subscribe(data => {
        this.notificationService.showSuccess('Export resumed', 2000);
        this.sharedService.reloadCurrentRoute();
        return data;
      });
    } else this.notificationService.showAlert('Export was already completed or terminated', 2000);
  }

  copyExport(sessionData: ISessionData) {
    const exportSettings = sessionData.request_RequestPath.split('/');
    const data = {
      configName: sessionData.config.name,
      type: undefined,
      group_id: undefined,
    };

    switch (exportSettings[0]) {
      case 'Patient':
        data.type = 'Patient';
        break;
      case 'Group':
        data.type = 'Group';
        data.group_id = exportSettings[1];
        break;
      default:
        data.type = 'Server';
        break;
    }

    this.router.navigate(['/exports/create'], {
      queryParams: { copy: true, data: JSON.stringify(data) },
      relativeTo: this.route,
    });
  }

  viewClick(sessionData: ISessionData): void {
    this.router.navigate(['/logs/list/' + sessionData.sessionId], {
      relativeTo: this.route,
    });
  }

  copyConfig(endpointUrl: string) {
    this.router.navigate(['/configs/edit', { endpointUrl }], { queryParams: { copy: true }, relativeTo: this.route });
  }

  editConfig(endpointUrl: string): void {
    this.router.navigate(['/configs/edit', { endpointUrl }], { relativeTo: this.route });
  }

  downloadConfig(configData: IConfig): void {
    const fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(configData)]));
    const fileLink = document.createElement('a');
    const name = configData.name.replace(/\s+/g, '') + '.json';
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    fileLink.click();
  }

  openConfirmationDialog(config: IConfig) {
    return this.dialog
      .open(ConfigDeleteComponent, {
        data: { endpoint_url: config.endpoint_url },
      })
      .afterClosed();
  }

  deleteConfig(endpoint_url: string) {
    const currentConfig: IConfig = this.configsService.findConfig(endpoint_url);
    if (currentConfig.endpoint_url === endpoint_url) {
      this.openConfirmationDialog(currentConfig).subscribe(data => {
        if (data) {
          this.configsService
            .deleteConfig(currentConfig.name)
            .pipe(tap(() => this.sharedService.showSuccess('Delete successful')))
            .subscribe();
        }
      });
    }
  }
}
