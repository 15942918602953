<fr-table Id="access-logs-table" [config]="tableConfig" [paginatorConfig]="paginatorConfig" [data]="data">
  <div class="custom-table-header-space" table-header-space>
    <button mat-flat-button class="fr-primary" color="primary" [matMenuTriggerFor]="menu">
      <mat-icon>add</mat-icon>
      New Configuration
      <mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="createMenu">
      <button mat-menu-item (click)="editClick.emit('')">Create new</button>
      <button mat-menu-item (click)="importClick.emit()">Import JSON</button>
    </mat-menu>
  </div>
</fr-table>
<div class="table-sub-header">
  <app-loading-spinner *ngIf="!data"></app-loading-spinner>
</div>