<app-list-table
  [data]="configs$ | async"
  (editClick)="editClick($event)"
  (importClick)="importClick()"
  (deleteConfig)="deleteConfig($event)"
  (viewClick)="viewClick($event)"
  (copyConfig)="copyConfig($event)"
  (download)="onDownload($event)"
>
</app-list-table>
