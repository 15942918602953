import { Column } from '@intersystems/table';

/** Shared utilities class */
export class SharedUtils {
  /** Get fr-table column definition with reasonable defaults*/
  static getTableColumn(
    id: string,
    title?: string,
    cellDisplayParam?: string | Column['cellDisplay']['getDisplay'],
  ): Column {
    let cellDisplay: Column['cellDisplay'];
    if (!title) title = id.charAt(0).toUpperCase() + id.slice(1);
    if (!cellDisplayParam) cellDisplay = { model: id };
    else if (typeof cellDisplayParam == 'string') cellDisplay = { model: cellDisplayParam };
    else if (typeof cellDisplayParam == 'function') cellDisplay = { getDisplay: cellDisplayParam };
    return { id, key: id, title, cellDisplay };
  }
}
