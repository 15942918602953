import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { Requirement } from '@intersystems/isc-form';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  /// Use this refresh$ observable in data services to indicate when data refresh is needed
  private _refresh = new BehaviorSubject<void>(null);
  get refresh$(): Observable<any> {
    return this._refresh.asObservable();
  }

  showSuccess(message: string) {
    this.notificationService.showSuccess(message, 5000);
  }

  showAlert(message: string) {
    this.notificationService.showAlert(message, 5000);
  }

  showInfo(message: string) {
    this.notificationService.showInfo(message, 5000);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
    this._refresh.next();
  }

  emitRefresh() {
    this._refresh.next();
  }

  generateDownloadJsonUrl(json: Record<string, unknown>, sanitized?: boolean) {
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
    const blobUrl = window.URL.createObjectURL(blob);
    return sanitized ? this.sanitizer.bypassSecurityTrustUrl(blobUrl) : blobUrl;
  }
  compareDates(a, b, isAsc) {
    return ((a ? new Date(a).getTime() : '') < (b ? new Date(b).getTime() : '') ? -1 : 1) * (isAsc ? 1 : -1);
  }
  clearNullProperties<T>(obj: T): T {
    return Object.entries(obj)
      .filter(([_, value]) => value != null)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as T);
  }
}
