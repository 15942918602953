import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfig } from 'src/app/core/interfaces/config';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent {
  @Input() data!: IConfig;
  @Input() metadata!: any;
  @Input() isConfigExist: boolean;

  @Output() postConfig: EventEmitter<any> = new EventEmitter<any>();

  configureClick(): void {
    this.removeUnusedAdapterPropertiesFromConfig('auth');
    this.removeUnusedAdapterPropertiesFromConfig('fetch');
    this.removeUnusedAdapterPropertiesFromConfig('storage');
    this.postConfig.emit(this.data);
  }

  removeUnusedAdapterPropertiesFromConfig(adapter: string) {
    const config = this.data[adapter + '_config'];
    const meta = this.metadata[adapter + '_adapters'].find(item => item.id == this.data[adapter + '_adapter']);
    Object.keys(config).forEach(configKey => {
      if (!meta.properties.find(property => property.name == configKey)) delete config[configKey];
    });
  }
}
