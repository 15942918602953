import { JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { EPresetOptions, PaginatorConfig, TableConfig } from '@intersystems/table';
import { FieldWrapper } from '@ngx-formly/core';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';

@Component({
  selector: 'app-isc-form-file-viewer-wrapper',
  templateUrl: './isc-form-field-file-viewer-wrapper.component.html',
  styleUrls: ['./isc-form-field-file-viewer-wrapper.component.scss'],
})
export class IscFormFieldFileViewerWrapperComponent extends FieldWrapper implements OnInit {
  field: Field;
  fileContents;

  ngOnInit(): void {
    this.field.formControl.value.body.text().then(file => {
      this.fileContents = file;
    });
  }
}
