import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditPageComponent } from './edit/edit-page.component';
import { ListPageComponent } from './list/list-page.component';

export const routes: Routes = [
  {
    path: 'list',
    component: ListPageComponent,
    data: {
      caption: 'List',
    },
  },
  {
    path: 'edit',
    component: EditPageComponent,
    data: {
      caption: 'Edit',
    },
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigsRoutingModule {}
