import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Route, Router } from '@angular/router';
import { AppNode } from '@intersystems/header';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private injector: Injector) {
    const navigationEvents$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => this.updateMenu(e.urlAfterRedirects)),
      takeUntil(this._unsubscribeAll),
    );
    navigationEvents$.subscribe();
  }

  appRoutes = this.router.config;
  menuData$ = new BehaviorSubject<AppNode[]>([]);
  activeAppId$ = new BehaviorSubject<string>('');

  invitationsCounter = 0;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  updateMenu(url?) {
    if (!url) url = this.router.url;

    this.menuData$.next(this.getRouteTree(this.appRoutes, url, 'nav-', this.activatedRoute.snapshot.firstChild));
  }

  getRouteTree(
    routes: Route[],
    url: string,
    prefix: string,
    activatedRouteSnapshot: ActivatedRouteSnapshot,
  ): AppNode[] {
    let tree: AppNode[] = [];
    const isAdmin = false;
    const isSe = false;
    routes.forEach(route => {
      if (route.data?.adminOnly && !isAdmin) return [];
      if (route.data?.seOnly && !isSe) return [];
      if (route.data?.caption) {
        const lastPath = route.path.split('/').pop();
        let hidden = false;
        // Add an ability to hide menu items based on route guards
        if (route.canActivate)
          route.canActivate.forEach(canActivateItem => {
            const guard = this.injector.get(canActivateItem);
            if (guard && !hidden) hidden = !guard.canActivate(activatedRouteSnapshot, null);
            if (prefix + lastPath == 'nav-deployments-irislogin') hidden = true;
          });
        const newNode: AppNode = {
          name: route.data.caption,
          id: prefix + lastPath,
          hidden,
        };

        tree.push(newNode);
      }
      if (route.path === '' && route.children) {
        tree = tree.concat(this.getRouteTree(route.children, url, prefix, activatedRouteSnapshot?.firstChild));
      }
    });
    return tree;
  }
}
