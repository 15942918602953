import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ILogFilter, ILogResponse } from '../core/interfaces/dataLogs';

@Injectable({ providedIn: 'root' })
export class LogsService {
  constructor(private httpClient: HttpClient) {}

  getLogs(filter: Partial<ILogFilter>): Observable<ILogResponse> {
    return this.httpClient.get<ILogResponse>(environment.API_URL + '/logs', { params: filter });
  }

  getLog(id: string | number): Observable<ILogResponse> {
    return this.httpClient.get<ILogResponse>(environment.API_URL + '/logs?_id=' + id);
  }

  getLogBySession(sessionId: string): Observable<ILogResponse> {
    return this.httpClient.get<ILogResponse>(environment.API_URL + `/logs?_sessionId=${sessionId}`);
  }
}
