import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  FDN,
  Section,
  Field,
  IscFormModalData,
  IscFormModalComponent,
  FIELD_TYPES,
  VALIDATORS,
  Option,
  Link,
} from '@intersystems/isc-form';

export enum FormModes {
  VIEW = 'view',
  EDIT = 'edit',
}

@Component({
  selector: 'app-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss'],
})
export class InfoButtonComponent {
  constructor(private dialog: MatDialog) {}
  @Input() title = '';
  @Input() infoText = '';
  @Input() linkArray: (string | Link)[] = [];
  @Input() buttonName = 'btn-info';
  @Input() class = '';
  @ViewChild('tooltip') tooltip: MatTooltip;
  infoClick() {
    this.openInfoFieldModal(this.title, this.linkArray);
    /*
    this.tooltip.show();
    setTimeout(() => {
      this.tooltip.message = this.infoText; //'need to implement';
      this.tooltip.hide();
    }, 10000);
    */
  }

  //openFieldDemoModal(title: string, type: FIELD_TYPES) {
  openInfoFieldModal(title: string, linkArray: (string | Link)[]) {
    const modalData: IscFormModalData = {
      modalTitle: '',
      iscFormInputs: {
        Id: '',
        FDN: {
          name: '',
          description: '',
          validateOn: 'change',
          autosave: {
            trigger: 'timeInterval',
            interval: 10000,
            maxIntervals: 12,
          },
          sectionLayout: {
            showSectionHeaders: false,
          },
          sections: [
            {
              name: '',
              fieldGroup: [],
              hideExpression: '',
              hide: false,
              fields: [
                {
                  hide: false,
                  type: 'instructions',
                  key: 'sample-instruction',
                  viewOnly: true,
                  data: {
                    //content: infoText,

                    linkArray: linkArray,

                    //content: "aaaaaaa" //`${this.infoText}`
                  },
                },
              ],
            },
          ],
        },
        formModel: {},
        mode: FormModes.EDIT,
        formConfig: {},
      },
    };

    modalData.iscFormInputs.Id = 'info-form';

    modalData.modalTitle = title;

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(IscFormModalComponent, {
      autoFocus: true, //![FIELD_TYPES.BINARY_CHECKBOX].includes(type),
      panelClass: 'isc-form-modal',
      data: modalData,
    });
  }
}
