import { Component, EventEmitter, Output } from '@angular/core';
import { FDN } from '@intersystems/isc-form';
import { BehaviorSubject } from 'rxjs';
import { ISessionFilter } from 'src/app/core/interfaces/sessions';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Output() applyFilter = new EventEmitter<ISessionFilter>();

  filter$ = new BehaviorSubject<ISessionFilter>({ _username: '', _status: '' });

  get filter() {
    return this.filter$.value;
  }

  set filter(val) {
    this.filter$.next(val);
  }

  filterFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'completion_time_from',
            key: '_completion_time_from',
            type: 'date-picker',
            templateOptions: {
              label: 'Completion Time From',
            },
          },
          {
            id: 'completion_time_to',
            key: '_completion_time_to',
            type: 'date-picker',
            templateOptions: {
              label: 'Completion Time To',
            },
          },
          {
            id: 'username',
            key: '_username',
            type: 'input',
            templateOptions: {
              label: 'User Name',
            },
          },
          {
            id: 'status',
            key: '_status',
            type: 'select',
            data: {
              displayField: 'name',
              uniqueValueField: 'value',
              optionIdField: 'name',
              onlySaveUniqueValueField: true,
            },
            templateOptions: {
              label: 'Status',
              options: [
                {
                  name: '',
                  value: '',
                },
                {
                  name: 'Terminated',
                  value: 'terminated',
                },
                {
                  name: 'Complete',
                  value: 'complete',
                },
              ],
            },
          },
        ],
      },
    ],
  };
}
