import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from '../../shared/services/shared.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorHttpResponse: HttpErrorResponse) => {
        let errorMsg = '';
        if (errorHttpResponse.error instanceof ErrorEvent) {
          // this is client side error
          errorMsg = `Error: ${errorHttpResponse.error.message}`;
        } else {
          // this is server side error
          errorMsg = `An issue occured while executing this call: ${errorHttpResponse.error.summary}`;
          this.sharedService.showAlert(errorMsg);
        }
        return throwError(errorMsg);
      }),
    );
  }
}
