import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogsService } from '../../services/logs.service';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfigsService } from 'src/app/services/configs.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ILogFilter, ILog, ILogResponse } from 'src/app/core/interfaces/dataLogs';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent implements OnInit {
  logsData$: Observable<ILogResponse>;
  logsData: ILogResponse;

  _filter = new BehaviorSubject<ILogFilter>({ _component: 'bfc' });
  filter$ = this._filter.asObservable();

  sessionId: string;

  constructor(
    private sharedService: SharedService,
    private logsService: LogsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.sessionId = this.route.snapshot.paramMap.get('sessionId');

    this.logsData$ = this.filter$.pipe(
      map(filter => this.sharedService.clearNullProperties(filter)),
      switchMap(filter => {
        return this.logsService.getLogs({ ...filter, _sessionId: this.sessionId ? this.sessionId : '' });
      }),
      map(data => {
        this.logsData = data;
        return data;
      }),
    );
  }

  viewClick(data: ILog): void {
    this.router.navigate(['/logs/view/' + data.id], {
      queryParams: Object.keys(data),
      relativeTo: this.route,
    });
  }

  onDownload(): void {
    const fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(this.logsData.log)]));
    const fileLink = document.createElement('a');

    const name = new Date().toISOString() + '-bulkfhir-logs.json';
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);

    fileLink.click();
  }
}
