<div style="max-width: 900px">
  <mat-horizontal-stepper #stepper [selectedIndex]="skipFirstStep?1:0">
    <mat-step label="Select Configuration">
      <fr-isc-form Id="select-config" [FDN]="selectConfigFDN" [formModel]="formModel"> </fr-isc-form>
      <div fxLayoutAlign="end">
        <button mat-raised-button color="primary" [disabled]="!formModel.endpoint_url" (click)="stepper.next()">
          Next
        </button>
      </div>
    </mat-step>
    <mat-step label="Export Settings">
      <fr-isc-form Id="export-settings" #exportConfigForm [FDN]="exportSetUpFDN" [formModel]="formModel">
      </fr-isc-form>
      <div fxLayoutGap="10px" fxLayoutAlign="end">
        <button mat-stroked-button color="primary" style="min-width: 112px;"
          (click)="stepper.previous()">Previous</button>
        <button mat-raised-button color="primary"
          [disabled]="exportConfigForm.form.invalid || this.isSelectedExportDisabled()"
          (click)="createExport.emit(formModel)">
          Export now
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>