import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPageComponent } from './list/list-page.component';
import { SharedModule } from '../shared/shared.module';
import { LogsRoutingModule } from './logs-routing.module';
import { ListTableComponent } from './list/list-table/list-table.component';
import { ViewPageComponent } from './view/view-page.component';
import { ViewTableComponent } from './view/view-logs-by-session-table/view-table.component';
import { FilterComponent } from './list/filter/filter.component';

@NgModule({
  declarations: [ListPageComponent, ListTableComponent, ViewPageComponent, ViewTableComponent, FilterComponent],
  imports: [CommonModule, SharedModule, LogsRoutingModule],
})
export class LogsModule {}
