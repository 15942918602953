import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogsService } from '../../services/logs.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss'],
})
export class ViewPageComponent implements OnInit {
  logDetailsData$: Observable<any>;

  constructor(private router: Router, private route: ActivatedRoute, private logsService: LogsService) {}

  ngOnInit(): void {
    this.logDetailsData$ = this.logsService.getLog(this.route.snapshot.paramMap.get('logId')).pipe(
      map(data => {
        const logProperties = [];
        Object.entries(data.log[0].doc).forEach(([key, value]) =>
          logProperties.push({
            key,
            value,
          }),
        );
        return logProperties;
      }),
    );
  }
}
