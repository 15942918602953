import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigsService } from '../../services/configs.service';
import { SharedService } from '../../shared/services/shared.service';
import { IConfig } from '../../core/interfaces/config';
import { EditFormService } from './edit-form.service';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss'],
})
export class EditPageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  config$: Observable<any>;
  metadata$: Observable<any>;

  endpointUrl!: string;

  isConfigExist: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configsService: ConfigsService,
    private editFormService: EditFormService,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.endpointUrl = this.route.snapshot.paramMap.get('endpointUrl');
    if (this.route.snapshot.queryParamMap.get('copy')) {
      this.initConfigData(true);
    } else this.initConfigData();

    this.metadata$ = this.configsService.getMetadata();
  }

  getConfigDataFromService(): void {
    this.config$ = this.editFormService.formModel.pipe(
      map(formModel => {
        return formModel;
      }),
      takeUntil(this._unsubscribeAll),
    );
  }

  initConfigData(copy?: boolean): void {
    this.config$ = this.configsService.getConfigs().pipe(
      map(data => {
        if (this.endpointUrl) {
          const config = this.configsService.findConfig(this.endpointUrl);
          if (copy)
            return {
              ...config,
              name: config.name.substring(0, 44) + '_copy',
              endpoint_url: config.endpoint_url.substring(0, 44) + '_copy',
            };
          this.isConfigExist = true;
          return config;
        } else {
          // check if it is a value from uploaded json configuration
          const uploadedConfiguration = this.editFormService.formModelData;
          if (uploadedConfiguration) {
            this.editFormService.changeFormModel(null);
            return uploadedConfiguration;
          } else {
            const defaultValues = {
              max_file_size: 1000000000,
              flush_interval_mins: 60,
              auto_start: true,
              patient_export: true,
              group_export: true,
              system_export: true,
              expire_after_mins: 1440,
            };
            return defaultValues;
          }
        }
      }),
    );
  }

  createOrEditConfig(data): void {
    this.configsService
      .postConfig({ bulkfhir_config: data })
      .pipe(
        switchMap(() => this.configsService.getConfigs()),
        tap(() =>
          this.sharedService.showSuccess(
            this.isConfigExist ? 'Successfully updated configuration' : 'Successfully created configuration',
          ),
        ),
        tap(() => this.sharedService.emitRefresh()),
        tap(() => this.router.navigate(['/configs'])),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
