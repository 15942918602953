import { Routes } from '@angular/router';
import { routes as configsRoutes } from './configs/configs-routing.module';
import { routes as exportsRoutes } from './exports/exports-routing.module';
import { routes as logsRoutes } from './logs/logs-routing.module';
import { routes as resourcecenterRoutes } from './resourcecenter/resourcecenter-routing.module';
import { ConfigsModule } from './configs/configs.module';
import { BreadcrumbsComponent } from './core/breadcrumbs/breadcrumbs.component';
import { ExportsModule } from './exports/exports.module';
import { LogsModule } from './logs/logs.module';
import { ResourceCenterModule } from './resourcecenter/resourcecenter.module';

export const routes: Routes = [
  {
    path: 'configs',
    loadChildren: () => ConfigsModule,
    data: {
      caption: 'Configurations',
      children: configsRoutes,
    },
  },
  {
    path: 'exports',
    loadChildren: () => ExportsModule,
    data: {
      caption: 'Exports',
      children: exportsRoutes,
    },
  },
  {
    path: 'logs',
    loadChildren: () => LogsModule,
    data: {
      caption: 'Logs',
      children: logsRoutes,
    },
  },
  {
    path: 'resourcecenter',
    loadChildren: () => ResourceCenterModule,
    data: {
      caption: 'Videos & Help',
      children: resourcecenterRoutes,
    },
  },
  {
    path: '',
    redirectTo: '/configs/list',
    pathMatch: 'full',
  },
  {
    path: 'reload',
    component: BreadcrumbsComponent,
    pathMatch: 'full',
  },
];
