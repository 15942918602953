import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { IConfig } from 'src/app/core/interfaces/config';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
})
export class ListTableComponent {
  @Input() data!: IConfig[];

  @Output() editClick = new EventEmitter<string>();
  @Output() importClick = new EventEmitter<void>();
  @Output() viewClick = new EventEmitter<string>();
  @Output() download = new EventEmitter<IConfig>();
  @Output() deleteConfig = new EventEmitter<string>();
  @Output() copyConfig = new EventEmitter<string>();

  tableConfig: TableConfig = {
    key: 'list-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No configurations available',
    stickyHeaderRow: true,
    useSearch: true,
    header: {
      title: 'Bulk FHIR Configurations',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      SharedUtils.getTableColumn('name', 'Name'),
      SharedUtils.getTableColumn('endpoint_url', 'Endpoint'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['view', 'copy', 'edit', 'download', 'delete'],
            copy: {
              id: 'copy',
              tooltip: { text: 'Copy' },
              customSvgIcon: 'custom-copy',
              callback: (clickEvent, row) => this.copyConfig.emit(row.endpoint_url),
            },
            download: {
              id: 'download',
              tooltip: { text: 'Download' },
              customSvgIcon: 'custom-download',
              callback: (clickEvent, row) => this.download.emit(row),
            },
            view: {
              id: 'view',
              tooltip: { text: 'View Exports' },
              callback: (_e, row) => this.viewClick.emit(row.name),
            },
            edit: {
              id: 'edit',
              customSvgIcon: 'custom-edit',
              callback: (_e, row) => this.editClick.emit(row.endpoint_url),
            },
            delete: {
              id: 'delete',
              tooltip: { text: 'Delete' },
              customSvgIcon: 'custom-delete',
              callback: (_e, row) => this.deleteConfig.emit(row.endpoint_url),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    length: this.data?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };

  constructor(public tableService: TableService) {}
}
