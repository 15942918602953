import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { ISessionData, ISessionFilter } from 'src/app/core/interfaces/sessions';
import { SharedUtils } from 'src/app/shared/shared-utils';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
})
export class ListTableComponent {
  @Input() dataComplete!: ISessionData[];
  @Input() dataNotComplete!: ISessionData[];

  @Output() viewClick = new EventEmitter<ISessionData>();
  @Output() createClick = new EventEmitter<void>();
  @Output() downloadClick = new EventEmitter<ISessionData>();
  @Output() copyExport = new EventEmitter<ISessionData>();
  @Output() pauseExport = new EventEmitter<ISessionData>();
  @Output() resumeExport = new EventEmitter<ISessionData>();
  @Output() cancelExport = new EventEmitter<ISessionData>();
  @Output() applyFilter = new EventEmitter<ISessionFilter>();

  tableConfigNoComplete: TableConfig = {
    key: 'list-inprogress-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No exports in progress',
    stickyHeaderRow: true,
    header: {
      title: 'In Progress',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      SharedUtils.getTableColumn('config.name', 'Configuration'),
      {
        id: 'request_RequestPath',
        key: 'request_RequestPath',
        title: 'Export Type',
        cellDisplay: {
          //   getDisplay: (row: any) => row.request_RequestPath.split('/')[0],
          getDisplay: (row: any) => row.request_RequestPath,
        },
      },
      {
        id: 'requestTime',
        key: 'requestTime',
        title: 'Launched',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.requestTime).toLocaleString(),
        },
      },
      SharedUtils.getTableColumn('username', 'User'),
      SharedUtils.getTableColumn('status', 'Status'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['pause', 'continue', 'delete'],
            pause: {
              id: 'pause',
              tooltip: { text: 'Pause Export' },
              callback: (_e, row: ISessionData) => this.pauseExport.emit(row),
              customSvgIcon: 'pause',
              hidden(row, column) {
                return row.status === 'pending';
              },
            },
            continue: {
              id: 'continue',
              tooltip: { text: 'Сontinue Export' },
              callback: (_e, row: ISessionData) => this.resumeExport.emit(row),
              customSvgIcon: 'continue',
              hidden(row, column) {
                return row.status !== 'pending';
              },
            },
            delete: {
              id: 'delete',
              customSvgIcon: 'stop',
              tooltip: { text: 'Cancel' },
              callback: (_e, row: ISessionData) => this.cancelExport.emit(row),
            },
          },
        },
      },
    ],
  };

  tableConfigComplete: TableConfig = {
    key: 'list-completed-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No exports completed',
    stickyHeaderRow: true,
    header: {
      title: 'Completed Exports',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      SharedUtils.getTableColumn('config.name', 'Configuration'),
      {
        id: 'request_RequestPath',
        key: 'request_RequestPath',
        title: 'Export Type',
        cellDisplay: {
          //   getDisplay: (row: any) => row.request_RequestPath.split('/')[0],
          getDisplay: (row: any) => row.request_RequestPath,
        },
      },
      {
        id: 'requestTime',
        key: 'requestTime',
        title: 'Launched',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.requestTime).toLocaleString(),
        },
      },
      {
        id: 'completedTime',
        key: 'completedTime',
        title: 'Completed',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.completionTime).toLocaleString(),
        },
      },
      SharedUtils.getTableColumn('username', 'User'),
      {
        id: 'exportSize',
        key: 'exportSize',
        title: 'File Size',
        cellDisplay: {
          getDisplay: (row: any) => Math.round(row.exportSize / 1000000) + ' MB',
        },
      },
      SharedUtils.getTableColumn('status', 'Status'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['copy', 'download', 'delete', 'view'],
            copy: {
              id: 'copy',
              tooltip: { text: 'Copy' },
              customSvgIcon: 'custom-copy',
              callback: (clickEvent, row) => this.copyExport.emit(row),
            },
            download: {
              id: 'download',
              tooltip: { text: 'Download' },
              customSvgIcon: 'custom-download',
              callback: (clickEvent, row: ISessionData) => this.downloadClick.emit(row),
            },
            view: {
              id: 'view',
              tooltip: { text: 'View logs' },
              customSvgIcon: 'view',
              callback: (_e, row: ISessionData) => this.viewClick.emit(row),
            },
          },
        },
      },
    ],
  };

  paginatorConfigComplete: PaginatorConfig = {
    length: this.dataComplete?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };

  paginatorConfigNoComplete: PaginatorConfig = {
    length: this.dataComplete?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
