import { Pipe, PipeTransform } from '@angular/core';
/*
 * Format number of kilobytes into number of megabytes
 */
@Pipe({ name: 'formatMBytes' })
export class FormatMBytesPipe implements PipeTransform {
  transform(value: number): number {
    return Number((value / 1024 / 1024).toFixed(2));
  }
}
