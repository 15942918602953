import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListPageComponent } from './list/list-page.component';
import { ViewPageComponent } from './view/view-page.component';

export const routes: Routes = [
  {
    path: 'list',
    component: ListPageComponent,
    data: {
      caption: 'List',
    },
  },
  {
    path: 'list/:sessionId',
    component: ListPageComponent,
    data: {
      caption: 'List',
    },
  },
  {
    path: 'view/:logId',
    component: ViewPageComponent,
    data: {
      caption: 'View',
    },
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogsRoutingModule {}
