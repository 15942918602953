import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FDN, VALIDATORS } from '@intersystems/isc-form';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-export-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss'],
})
export class CreateFormComponent implements OnChanges, OnDestroy {
  @Input() configs: any[];
  @Input() formModel: any;
  @Input() skipFirstStep: boolean;

  @Output() createExport: EventEmitter<any> = new EventEmitter<any>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  permittedExports: BehaviorSubject<any> = new BehaviorSubject<any>([
    {
      name: 'Server',
      value: 'Server',
      disabled: false,
    },
    {
      name: 'Group',
      value: 'Group',
      disabled: false,
    },
    {
      name: 'Patient',
      value: 'Patient',
      disabled: false,
    },
  ]);

  _configsOptions = new BehaviorSubject<any>([]);
  configsOptions$ = this._configsOptions.asObservable();

  selectConfigFDN: FDN = null;
  exportSetUpFDN: FDN = null;

  ngOnChanges(changes) {
    if (!changes) return;
    const configsOptions = changes.configs?.currentValue?.map(el => ({
      name: el.name,
      value: el.endpoint_url,
    }));
    this._configsOptions.next(configsOptions);
    this.setFDN();
  }

  setFDN(): void {
    const selectConfigFDN: FDN = {
      name: '',
      description: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: true, showSectionCount: false },
      sections: [
        {
          name: 'Server Configuration',
          fields: [
            {
              id: 'endpoint_url',
              key: 'endpoint_url',
              type: 'select',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Configuration is a required field',
              },
              templateOptions: {
                asyncOptions: () => this.configsOptions$,
                label: 'Configuration',
                required: true,
              },
              data: {
                displayField: 'name',
                uniqueValueField: 'value',
                onlySaveUniqueValueField: true,
                optionIdField: 'value',
              },
              hooks: {
                onInit: field =>
                  field.formControl.valueChanges
                    .pipe(
                      tap(value => this.setPermittedExports(value)),
                      takeUntil(this._unsubscribeAll),
                    )
                    .subscribe(),
              },
            },
          ],
        },
      ],
    };

    const exportSetUpFDN: FDN = {
      name: '',
      description: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: true, showSectionCount: false },
      sections: [
        {
          name: 'Export Set Up',
          fields: [
            {
              id: 'type',
              key: 'type',
              type: 'radio',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Permitted Exports is a required field',
              },
              templateOptions: {
                label: 'Permitted Exports',
                required: true,
                asyncOptions: () => this.permittedExports.asObservable(),
              },
              data: {
                displayField: 'name',
                uniqueValueField: 'value',
              },
            },
            {
              id: 'group_id',
              key: 'group_id',
              type: 'input',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Group ID is a required field',
              },
              templateOptions: {
                label: 'Group ID',
                required: true,
                requirements: [
                  {
                    key: 'number',
                    id: 'number',
                    regEx: '[0-9]',
                    message: 'Use a number (e.g., 1234)',
                  },
                ],
              },
              hideExpression: model => model.type?.value !== 'Group',
            },
            {
              id: 'since',
              key: 'since',
              className: 'dateSince',
              type: 'date-picker',
              templateOptions: {
                label: 'Since',
              },
              data: {
                time: {
                  allowTimePickingToggle: true,
                  toggleTime: false,
                  hideSeconds: true,
                  format: '24',
                },
              },
            },
          ],
        },
      ],
    };

    this.selectConfigFDN = { ...this.selectConfigFDN, ...selectConfigFDN };
    this.exportSetUpFDN = { ...this.exportSetUpFDN, ...exportSetUpFDN };
  }

  setPermittedExports(endpoint_url: string): void {
    const permittedExports = this.permittedExports.getValue();

    const config = this.configs.find(config => config.endpoint_url === endpoint_url);

    permittedExports.forEach(item => {
      if (item.name === 'Server') {
        item.disabled = !config.system_export;
      }

      if (item.name === 'Group') {
        item.disabled = !config.group_export;
      }

      if (item.name === 'Patient') {
        item.disabled = !config.patient_export;
      }
    });

    return this.permittedExports.next(permittedExports);
  }

  isSelectedExportDisabled(): boolean {
    let result = true;
    this.permittedExports
      .asObservable()
      .pipe(
        map(permittedExports =>
          this.formModel.type.value !== null
            ? (result = permittedExports.find(exportItem => exportItem.value === this.formModel.type.value).disabled)
            : null,
        ),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();

    return result;
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
