import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-config-delete',
  templateUrl: './config-import.component.html',
})
export class ConfigImportComponent {
  @ViewChild('_bundleFile', { static: false })
  fileInput: ElementRef;

  fileName: string;

  uploadedConfig: any;

  constructor(
    public dialogRef: MatDialogRef<ConfigImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
  ) {}

  public addFile(event) {
    if (!event.target.files[0]) return;
    const file = event.target.files[0];
    if (file.type !== 'application/json') {
      this.sharedService.showAlert(`Type of file ${file.name} is not allowed`);
      this.removeFile();
      return;
    }

    const reader = new FileReader();

    reader.onload = e => {
      this.uploadedConfig = JSON.parse(e.target.result as string);
      this.fileName = file.name;
    };

    reader.readAsText(file);
  }

  public removeFile() {
    this.fileInput.nativeElement.value = '';
    this.uploadedConfig = undefined;
  }
}
