import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPageComponent } from './list/list-page.component';
import { SharedModule } from '../shared/shared.module';
import { ConfigsRoutingModule } from './configs-routing.module';
import { ListTableComponent } from './list/list-table/list-table.component';
import { EditFormComponent } from './edit/edit-form/edit-form.component';
import { EditPageComponent } from './edit/edit-page.component';
import { SettingsComponent } from './edit/edit-form/settings/settings.component';
import { AdapterComponent } from './edit/edit-form/adapter/adapter.component';
import { EditFormService } from './edit/edit-form.service';

@NgModule({
  declarations: [
    ListPageComponent,
    ListTableComponent,
    EditFormComponent,
    EditPageComponent,
    SettingsComponent,
    AdapterComponent,
  ],
  imports: [CommonModule, SharedModule, ConfigsRoutingModule],
  providers: [EditFormService],
})
export class ConfigsModule {}
