import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ISessionData, ISessionFilter } from 'src/app/core/interfaces/sessions';
import { SessionsService } from 'src/app/services/sessions.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent implements OnInit {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionsService,
    private sharedService: SharedService,
  ) {}

  public exportsComplete$: Observable<ISessionData[]>;
  public exportsNotComplete$: Observable<ISessionData[]>;

  _filter = new BehaviorSubject<ISessionFilter>({});
  filter$ = this._filter.asObservable();

  ngOnInit(): void {
    this.exportsNotComplete$ = this.sessionService.getSessions({ _active: 1 }).pipe(map(data => data.sessions));

    this.exportsComplete$ = this.filter$.pipe(
      map(filter => this.sharedService.clearNullProperties(filter)),
      switchMap(filter => this.sessionService.getSessions({ ...filter, _active: 0 })),
      map(data => data.sessions),
    );
  }

  viewClick(sessionData: ISessionData): void {
    this.router.navigate(['/logs/list/' + sessionData.sessionId], {
      relativeTo: this.route,
    });
  }

  createClick(): void {
    this.router.navigate(['../create'], { relativeTo: this.route });
  }

  copyExport(sessionData: ISessionData) {
    const exportSettings = sessionData.request_RequestPath.split('/');
    const data = {
      configName: sessionData.config.name,
      type: undefined,
      group_id: undefined,
    };

    switch (exportSettings[0]) {
      case 'Patient':
        data.type = 'Patient';
        break;
      case 'Group':
        data.type = 'Group';
        data.group_id = exportSettings[1];
        break;
      default:
        data.type = 'Server';
        break;
    }

    this.router.navigate(['../create'], {
      queryParams: { copy: true, data: JSON.stringify(data) },
      relativeTo: this.route,
    });
  }

  downloadClick(sessionData: ISessionData): void {
    const request_SessionApplication = sessionData.request_SessionApplication;
    this.router.navigate(['../' + sessionData.sessionId], {
      queryParams: { endpoint_url: request_SessionApplication },
      relativeTo: this.route,
    });
  }

  cancelExport(sessionData: ISessionData): any {
    this.sessionService.deleteSession(sessionData.sessionId).subscribe(data => {
      this.notificationService.showSuccess('Export canceled', 2000);
      this.sharedService.reloadCurrentRoute();
      return data;
    });
  }

  pauseExport(sessionData: ISessionData): any {
    this.sessionService.turnPauseSession(sessionData.sessionId).subscribe(data => {
      this.notificationService.showSuccess('Export Paused', 2000);
      this.sharedService.reloadCurrentRoute();
      return data;
    });
  }

  resumeExport(sessionData: ISessionData): any {
    this.sessionService.turnResumeSession(sessionData.sessionId).subscribe(data => {
      this.notificationService.showSuccess('Export Resumed', 2000);
      this.sharedService.reloadCurrentRoute();
      return data;
    });
  }
}
