import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigDeleteComponent } from 'src/app/configs/dialogs/config-delete/config-delete.component';
import { IConfig, IConfigResponse } from 'src/app/core/interfaces/config';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ConfigsService } from '../../services/configs.service';
import { ConfigImportComponent } from '../dialogs/config-import/config-import.component';
import { EditFormService } from '../edit/edit-form.service';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss'],
})
export class ListPageComponent implements OnInit {
  constructor(
    private configsService: ConfigsService,
    private sharedService: SharedService,
    private editFormService: EditFormService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  configs$: Observable<IConfig[]>;

  ngOnInit(): void {
    this.configs$ = this.configsService.getConfigs().pipe(map(data => data.configs));
  }

  editClick(endpointUrl: string): void {
    this.router.navigate(['../edit', { endpointUrl }], { relativeTo: this.route });
  }

  importClick(): void {
    const dialogRef = this.dialog.open(ConfigImportComponent);

    dialogRef
      .afterClosed()
      .pipe(
        map(data => {
          if (data) {
            this.editFormService.changeFormModel(data);
            this.router.navigate(['../edit'], { relativeTo: this.route });
          }
        }),
      )
      .subscribe();
  }

  openConfirmationDialog(config: IConfig) {
    return this.dialog
      .open(ConfigDeleteComponent, {
        data: { endpoint_url: config.endpoint_url },
      })
      .afterClosed();
  }

  copyConfig(endpointUrl: string) {
    this.router.navigate(['../edit', { endpointUrl }], { queryParams: { copy: true }, relativeTo: this.route });
  }

  onDownload(configData: IConfig): void {
    const fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(configData)]));
    const fileLink = document.createElement('a');

    const name = configData.name.replace(/\s+/g, '') + '.json';
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  deleteConfig(endpoint_url: string) {
    const currentConfig: IConfig = this.configsService.findConfig(endpoint_url);
    if (currentConfig.endpoint_url === endpoint_url) {
      this.openConfirmationDialog(currentConfig).subscribe(data => {
        if (data) {
          this.configsService
            .deleteConfig(currentConfig.name)
            .pipe(tap(() => this.sharedService.showSuccess('Delete successful')))
            .subscribe();
        }
      });
    }
  }

  viewClick(name: string): void {
    this.router.navigate(['/exports/view', name], { relativeTo: this.route });
  }
}
