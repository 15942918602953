import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { ILogFilter, ILog, ILogResponse } from 'src/app/core/interfaces/dataLogs';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
})
export class ListTableComponent {
  @Input() logsData: ILogResponse = null;

  @Output() viewClick: EventEmitter<any> = new EventEmitter<ILog>();
  @Output() download: EventEmitter<any> = new EventEmitter<void>();
  @Output() applyFilter = new EventEmitter<ILogFilter>();

  tableConfig: TableConfig = {
    key: 'logs-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No logs available',
    stickyHeaderRow: true,
    header: {
      title: 'Logs',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      SharedUtils.getTableColumn('lastModified', 'Last Modified'),
      SharedUtils.getTableColumn('component', 'Component'),
      SharedUtils.getTableColumn('type', 'Type'),
      SharedUtils.getTableColumn('endpoint_url', 'Endpoint URL'),
      SharedUtils.getTableColumn('request_path', 'Request Path'),
      SharedUtils.getTableColumn('status', 'Session Status'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: { text: 'View log details' },
              callback: (clickEvent, row) => this.viewClick.emit(row),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    length: this.logsData?.log.length,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
