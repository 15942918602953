import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableConfig, PaginatorConfig, ActionsIconsConfig } from '@intersystems/table';
import { IConfig } from 'src/app/core/interfaces/config';

import { ISessionData } from 'src/app/core/interfaces/sessions';
import { SharedUtils } from 'src/app/shared/shared-utils';

@Component({
  selector: 'app-view-table',
  templateUrl: './view-table.component.html',
  styleUrls: ['./view-table.component.scss'],
})
export class ViewTableComponent {
  @Input() dataNoComplete!: ISessionData[];

  @Input() dataComplete!: ISessionData[];

  @Input() currentConfig!: IConfig;

  @Input() configName: string;

  @Input() configSize: string;

  @Output() createClick = new EventEmitter<void>();
  @Output() progressExport = new EventEmitter<string>();
  @Output() cancelExport = new EventEmitter<string>();
  @Output() downloadExport = new EventEmitter<string>();
  @Output() goToConfigurations = new EventEmitter<void>();
  @Output() copyConfigClick = new EventEmitter();
  @Output() editConfigClick = new EventEmitter();
  @Output() downloadConfigClick = new EventEmitter();
  @Output() deleteConfigClick = new EventEmitter();
  @Output() copyExportClick = new EventEmitter<ISessionData>();
  @Output() viewClick = new EventEmitter<ISessionData>();

  onCopyConfig() {
    this.copyConfigClick.emit(this.currentConfig.endpoint_url);
  }

  onEditConfig() {
    this.editConfigClick.emit(this.currentConfig.endpoint_url);
  }

  onDownloadConfig() {
    this.downloadConfigClick.emit(this.currentConfig);
  }

  onDeleteConfig() {
    this.deleteConfigClick.emit(this.currentConfig.endpoint_url);
  }

  tableConfigNoComplete: TableConfig = {
    key: 'list-inprogress-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No exports in progress',
    stickyHeaderRow: true,
    header: {
      title: 'In Progress',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      {
        id: 'request_RequestPath',
        key: 'request_RequestPath',
        title: 'Export Type',
        cellDisplay: {
          getDisplay: (row: any) => row.request_RequestPath,
        },
      },
      {
        id: 'requestTime',
        key: 'requestTime',
        title: 'Launched',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.requestTime).toLocaleString(),
        },
      },
      SharedUtils.getTableColumn('username', 'User'),
      SharedUtils.getTableColumn('status', 'Status'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['pause', 'continue', 'delete'],
            pause: {
              id: 'pause',
              tooltip: { text: 'Pause Export' },
              callback: (_e, row: ISessionData) => this.progressExport.emit(row.sessionId),
              customSvgIcon: 'pause',
              hidden(row, column) {
                return row.status === 'pending';
              },
            },
            continue: {
              id: 'continue',
              tooltip: { text: 'Сontinue Export' },
              callback: (_e, row: ISessionData) => this.progressExport.emit(row.sessionId),
              customSvgIcon: 'continue',
              hidden(row, column) {
                return row.status !== 'pending';
              },
            },
            delete: {
              id: 'delete',
              customSvgIcon: 'stop',
              tooltip: { text: 'Cancel' },
              callback: (_e, row: ISessionData) => this.cancelExport.emit(row.sessionId),
            },
          },
        },
      },
    ],
  };
  tableConfigComplete: TableConfig = {
    key: 'list-completed-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No exports completed',
    stickyHeaderRow: true,
    header: {
      title: 'Completed Exports',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [
      {
        id: 'request_RequestPath',
        key: 'request_RequestPath',
        title: 'Export Type',
        cellDisplay: {
          getDisplay: (row: any) => row.request_RequestPath,
        },
      },
      {
        id: 'requestTime',
        key: 'requestTime',
        title: 'Launched',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.requestTime).toLocaleString(),
        },
      },
      {
        id: 'completedTime',
        key: 'completedTime',
        title: 'Completed',
        cellDisplay: {
          getDisplay: (row: any) => new Date(row.completionTime).toLocaleString(),
        },
      },
      {
        id: 'exportSize',
        key: 'exportSize',
        title: 'File Size',
        cellDisplay: {
          getDisplay: (row: any) => Math.round(row.exportSize / 1000000) + ' MB',
        },
      },
      SharedUtils.getTableColumn('username', 'User'),
      SharedUtils.getTableColumn('status', 'Status'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['copy', 'download', 'view'],
            copy: {
              id: 'copy',
              tooltip: { text: 'Copy' },
              customSvgIcon: 'custom-copy',
              callback: (clickEvent, row) => this.copyExportClick.emit(row),
            },
            download: {
              id: 'download',
              tooltip: { text: 'Download' },
              customSvgIcon: 'custom-download',
              callback: (_e, row: ISessionData) => this.downloadExport.emit(row.sessionId),
            },
            view: {
              id: 'view',
              tooltip: { text: 'View logs' },
              customSvgIcon: 'view',
              callback: (_e, row: ISessionData) => this.viewClick.emit(row),
            },
          },
        },
      },
    ],
  };

  paginatorConfigComplete: PaginatorConfig = {
    length: this.dataComplete?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };

  paginatorConfigNoComplete: PaginatorConfig = {
    length: this.dataComplete?.length,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
