import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ISessionData, ISessionResponse } from '../core/interfaces/sessions';

@Injectable({ providedIn: 'root' })
export class SessionsService {
  constructor(private httpClient: HttpClient) {}

  getSessions(filter): Observable<ISessionResponse> {
    return this.httpClient.get<ISessionResponse>(environment.API_URL + '/sessions', { params: filter });
  }

  getSession(sessionId: string): Observable<ISessionData> {
    return this.httpClient.get<any>(environment.API_URL + `/session/${sessionId}/status`);
  }

  getExportedItem(url: string): Observable<string> {
    return this.httpClient.get(url, { responseType: 'text' });
  }

  postSession(data: ISessionData): Observable<ISessionData> {
    return this.httpClient.post<ISessionData>(environment.API_URL + '/session', data);
  }

  deleteSession(id: string): Observable<any> {
    return this.httpClient.get<any>(environment.API_URL + `/session/${id}/cancel`);
  }

  turnPauseSession(id: string): Observable<void> {
    return this.httpClient.get<void>(environment.API_URL + `/session/${id}/pause`);
  }

  turnResumeSession(id: string): Observable<void> {
    return this.httpClient.get<void>(environment.API_URL + `/session/${id}/resume`);
  }
}
