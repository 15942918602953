import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionsService } from '../../services/sessions.service';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-exported-files',
  templateUrl: './exported-files-page.component.html',
  styleUrls: ['./exported-files-page.component.scss'],
})
export class ExportedFilesPageComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  sessionData$: Observable<any>;

  constructor(private route: ActivatedRoute, private sessionsService: SessionsService) {}

  ngOnInit(): void {
    const sessionId = this.route.snapshot.paramMap.get('sessionId');

    this.sessionData$ = this.sessionsService.getSession(sessionId).pipe(map(data => data));
  }

  onDownload(data: { name: string; url: string }): void {
    this.sessionsService
      .getExportedItem(data.url)
      .pipe(
        map(exportedData => {
          const fileURL = window.URL.createObjectURL(new Blob([exportedData]));
          const fileLink = document.createElement('a');

          const name = data.url.split('/').pop();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);

          fileLink.click();
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
