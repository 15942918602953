import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ExportsRoutingModule } from './exports-routing.module';
import { ListPageComponent } from './list/list-page.component';
import { ListTableComponent } from './list/list-table/list-table.component';
import { ViewPageComponent } from './view/view-page.component';
import { ViewTableComponent } from './view/view-table/view-table.component';
import { CreatePageComponent } from './create/create-page.component';
import { CreateFormComponent } from './create/create-form/create-form.component';
import { ExportedFilesPageComponent } from './exported-files/exported-files-page.component';
import { ExportedFilesTableComponent } from './exported-files/exported-files-table/exported-files-table.component';
import { FilterComponent } from './filter/filter.component';

@NgModule({
  declarations: [
    ListPageComponent,
    ListTableComponent,
    ViewPageComponent,
    ViewTableComponent,
    CreatePageComponent,
    CreateFormComponent,
    ExportedFilesPageComponent,
    ExportedFilesTableComponent,
    FilterComponent,
  ],
  imports: [CommonModule, SharedModule, ExportsRoutingModule],
})
export class ExportsModule {}
