<fr-table Id="logs-table" [config]="tableConfig" [paginatorConfig]="paginatorConfig" [data]="logsData?.log">
  <app-filter above-table-body-space (applyFilter)="applyFilter.emit($event)"></app-filter>
  <div class="custom-table-header-space" table-header-space>
    <button id="btn-download-logs" mat-flat-button class="fr-primary" color="primary" (click)="download.emit()"
      [disabled]="logsData?.log?.length<1">
      Download logs
    </button>
  </div>
</fr-table>
<div class="table-sub-header">
  <app-loading-spinner *ngIf="!logsData"></app-loading-spinner>
</div>