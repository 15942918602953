// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://bulkfhir.banksiaglobal.com';

export const environment = {
  production: false, //either true or false
  API_URL: `${URL}/csp/healthshare/fhirserver/buklfhir/api`,
};
