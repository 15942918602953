import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig } from '@intersystems/table';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-view-logs-table',
  templateUrl: './view-table.component.html',
  styleUrls: ['./view-table.component.scss'],
})
export class ViewTableComponent {
  @Input() data: any = null;

  @Output() download: EventEmitter<any> = new EventEmitter<void>();

  tableConfig: TableConfig = {
    key: 'logs-details-table',
    cssNoDataMessageClass: 'no-data-message',
    noDataMessage: 'No data available',
    stickyHeaderRow: true,
    header: {
      title: 'Log details',
    },
    searchConfig: {
      placeholderLabel: 'Search',
      noEntriesFoundLabel: 'No results',
      hideClearSearchButton: false,
      selectedRowHiddenLabel: (hiddenRowCount: number) => {
        return hiddenRowCount + ' selected rows are hidden';
      },
    },
    columns: [SharedUtils.getTableColumn('key', 'Property'), SharedUtils.getTableColumn('value', 'Value')],
  };

  paginatorConfig: PaginatorConfig = {
    length: this.data?.length,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50],
  };
}
