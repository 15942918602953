<div class="filter">
    <fr-isc-form Id="filterForm" [FDN]="FDN" [formModel]="filter"></fr-isc-form>
    <div class="settings">
        <button mat-button color="primary" id="btn-clear-fiters" (click)="applyFilter.emit(filter={})">
            Clear Filters
        </button>
        <button mat-raised-button color="primary" id="btn-apply-fiters" (click)="applyFilter.emit(filter)">
            Apply
        </button>
    </div>
</div>