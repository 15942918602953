import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { IscFormComponent, IscFormModule } from '@intersystems/isc-form';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { IscFormFieldHintWrapperComponent } from './components/isc-form-field-hint-wrapper/isc-form-field-hint-wrapper.component';
import { IscFormFieldInlineButtonsWrapperComponent } from './components/isc-form-field-inline-buttons-wrapper/isc-form-field-inline-buttons-wrapper.component';
import { IscFormSectionToggleWrapperComponent } from './components/isc-form-section-toggle-wrapper/isc-form-section-toggle-wrapper.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { MatButtonModule } from '@angular/material/button';
import { SectionComponent } from './components/section/section.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedIconsModule } from './shared-icons.module';
import { TableModule } from '@intersystems/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormatGBytesPipe } from './pipes/formatGBytes.pipe';
import { FormatMBytesPipe } from './pipes/formatMBytes.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule } from '@angular/common/http';
import { IscFormFieldHintInfoComponent } from './components/isc-form-field-hint-info/isc-form-field-hint-info.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { IscFormFieldFileViewerWrapperComponent } from './components/isc-form-field-file-viewer-wrapper/isc-form-field-file-viewer-wrapper.component';

import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { LoadingSpinner } from './components/loading-spinner/loading-spinner';
import { ReloadComponentDirective } from './directives/reload-component.directive';
import { IscFormSelectWrapperComponent } from './components/isc-form-select-wrapper/isc-form-select-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    IscFormModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    RouterModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'inline-buttons-wrapper',
          component: IscFormFieldInlineButtonsWrapperComponent,
        },
        {
          name: 'section-toggle-wrapper',
          component: IscFormSectionToggleWrapperComponent,
        },
        { name: 'hint-wrapper', component: IscFormFieldHintWrapperComponent },
        { name: 'select-wrapper', component: IscFormSelectWrapperComponent },
        { name: 'hint-info', component: IscFormFieldHintInfoComponent },
        { name: 'file-viewer-wrapper', component: IscFormFieldFileViewerWrapperComponent },
      ],
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    SharedIconsModule,
    TableModule.forRoot({ IscFormComponent: IscFormComponent }),
    MatProgressSpinnerModule,
    MatTabsModule,
    FlexModule,
    FlexLayoutModule,
    MatSliderModule,
    MatProgressBarModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatInputModule,
    HttpClientModule,
    MatTreeModule,
    MatSidenavModule,
    DragDropModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  declarations: [
    IscFormFieldInlineButtonsWrapperComponent,
    IscFormFieldHintWrapperComponent,
    IscFormSelectWrapperComponent,
    IscFormFieldHintInfoComponent,
    IscFormSectionToggleWrapperComponent,
    IscFormFieldFileViewerWrapperComponent,
    SectionComponent,
    CopyButtonComponent,
    FormatGBytesPipe,
    InfoButtonComponent,
    FormatMBytesPipe,
    LoadingSpinner,
    ReloadComponentDirective,
  ],
  exports: [
    IscFormModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    SectionComponent,
    MatButtonModule,
    CopyButtonComponent,
    SharedIconsModule,
    TableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTabsModule,
    FlexModule,
    MatSliderModule,
    MatProgressBarModule,
    FormatGBytesPipe,
    FormatMBytesPipe,
    FlexLayoutModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    MatInputModule,
    MatSlideToggleModule,
    HttpClientModule,
    InfoButtonComponent,
    MatTreeModule,
    MatSidenavModule,
    DragDropModule,
    LoadingSpinner,
    MatTooltipModule,
    ReloadComponentDirective,
    MatMenuModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
