import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from '@intersystems/notification';
import { AuthInterceptor } from './core/auth.interceptor';
import { CoreModule } from './core/core.module';
import { ConfigDeleteComponent } from './configs/dialogs/config-delete/config-delete.component';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { ConfigImportComponent } from './configs/dialogs/config-import/config-import.component';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

@NgModule({
  declarations: [AppComponent, ConfigDeleteComponent, ConfigImportComponent],
  imports: [CoreModule],
  providers: [NotificationService, httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
