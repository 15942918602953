import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IConfig, IConfigRequest, IConfigResponse } from '../core/interfaces/config';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigsService {
  private _configs: BehaviorSubject<IConfigResponse> = new BehaviorSubject<IConfigResponse>(null);

  constructor(private httpClient: HttpClient) {}

  get configs(): IConfig[] {
    return this._configs.value.configs;
  }

  getConfigs(): Observable<IConfigResponse> {
    return this.httpClient
      .get<IConfigResponse>(environment.API_URL + '/configs')
      .pipe(tap(data => this._configs.next(data)));
  }

  getMetadata(): Observable<any> {
    return this.httpClient.get<any>(environment.API_URL + '/metadata');
  }

  postConfig(data: IConfigRequest): Observable<void> {
    return this.httpClient.post<void>(environment.API_URL + '/config', data);
  }

  deleteConfig(name: string): Observable<void> {
    return this.httpClient.delete<void>(environment.API_URL + '/config/' + encodeURIComponent(name));
  }

  findConfig(endpoint_url: string): IConfig {
    if (!this.configs) return null;
    let foundConfig = null;
    this.configs.forEach(config => {
      if (config.endpoint_url === endpoint_url) {
        foundConfig = config;
      }
    });
    return foundConfig;
  }
}
