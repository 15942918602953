<h2>All Exports <a mat-flat-button class="fr-primary new-button" color="primary" (click)="createClick.emit()">
    <mat-icon>add</mat-icon>
    New Export Request
  </a></h2>
<fr-table Id="access-logs-table" [config]="tableConfigNoComplete" [paginatorConfig]="paginatorConfigNoComplete"
  [data]="dataNotComplete">
</fr-table>
<div class="table-sub-header">
  <app-loading-spinner *ngIf="!dataNotComplete"></app-loading-spinner>
</div>
<fr-table Id="access-logs-table" [config]="tableConfigComplete" [paginatorConfig]="paginatorConfigComplete"
  [data]="dataComplete">
  <app-filter (applyFilter)="applyFilter.emit($event)" above-table-body-space></app-filter>
</fr-table>
<div class="table-sub-header">
  <app-loading-spinner *ngIf="!dataComplete"></app-loading-spinner>
</div>