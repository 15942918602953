import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FDN, VALIDATORS, FormButton } from '@intersystems/isc-form';
import { BehaviorSubject } from 'rxjs';
import { IConfig } from 'src/app/core/interfaces/config';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  @Input() formModel: Partial<IConfig> = {};
  @Input() metadata!: any;
  @Input() mode!: 'edit' | 'view';
  @Input() isConfigExist: boolean;

  @Output() nextClick = new EventEmitter<void>();
  @Output() previousClick = new EventEmitter<void>();

  _configs = new BehaviorSubject<IConfig[]>([]);
  configs$ = this._configs.asObservable();

  FDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: true, showSectionCount: false },
    sections: [
      {
        name: 'General',
        fields: [
          {
            id: 'name',
            key: 'name',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Name is a required field',
            },
            templateOptions: {
              label: 'Name',
              required: true,
              maxLength: 50,
              [VALIDATORS.MAX_LENGTH]: 50,
            },
          },
          {
            id: 'auto_start',
            key: 'auto_start',
            type: 'binary-checkbox',
            templateOptions: {
              label: 'Auto-start exports',
            },
            data: {
              hint: 'Flag indicating whether export jobs will be started as soon as they come in, or if they require manual approval.',
            },
          },
        ],
      },
      {
        name: 'Endpoints',
        fields: [
          {
            id: 'endpoint_url',
            key: 'endpoint_url',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'BFC Endpoint is a required field',
            },
            viewOnly: () => this.isConfigExist,
            templateOptions: {
              label: 'BFC Endpoint',
              required: true,
              maxLength: 50,
              [VALIDATORS.MAX_LENGTH]: 50,
            },
          },
          {
            id: 'core_fhir_package',
            key: 'core_fhir_package',
            type: 'input',
            hideExpression: () => !this.isConfigExist,
            viewOnly: true,
            templateOptions: {
              label: 'Core FHIR Package',
            },
          },
        ],
      },
      {
        name: 'Permitted Exports',
        fields: [
          {
            id: 'patient_export',
            key: 'patient_export',
            type: 'binary-checkbox',
            templateOptions: {
              label: 'Patient',
            },
          },
          {
            id: 'group_export',
            key: 'group_export',
            type: 'binary-checkbox',
            templateOptions: {
              label: 'Group',
            },
          },
          {
            id: 'system_export',
            key: 'system_export',
            type: 'binary-checkbox',
            templateOptions: {
              label: 'System',
            },
          },
        ],
      },
      {
        name: 'Storage Setings',
        fields: [
          {
            id: 'expire_after_mins',
            key: 'expire_after_mins',
            type: 'number',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Expire after is a required field',
            },
            templateOptions: {
              label: 'Expire After (mins)',
            },
            parsers: [value => parseInt(value)],
          },
          {
            id: 'max_file_size',
            key: 'max_file_size',
            type: 'number',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Max file size is a required field',
            },
            templateOptions: {
              label: 'Max file size (MB)',
              required: true,
            },
            parsers: [value => parseInt(value)],
          },
          {
            id: 'flush_interval_mins',
            key: 'flush_interval_mins',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Flush Interval is a required field',
            },
            templateOptions: {
              label: 'Flush Interval (mins)',
              required: true,
              type: 'number',
            },
            parsers: [value => parseInt(value)],
          },
        ],
      },
      {
        name: 'SSL Setings',
        fields: [
          {
            id: 'ssl_host',
            key: 'ssl_host',
            type: 'input',
            templateOptions: {
              label: 'SSL Host',
            },
          },
          {
            id: 'ssl_port',
            key: 'ssl_port',
            type: 'number',
            templateOptions: {
              label: 'SSL Port',
            },
            parsers: [value => parseInt(value)],
          },
          {
            id: 'ssl_prefix',
            key: 'ssl_prefix',
            type: 'input',
            templateOptions: {
              label: 'SSL Prefix',
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-next',
      text: 'Next',
      buttonClass: 'primary',
      type: 'submit',
      callback: (_event, _button, formModel) => {
        this.nextClick.emit(formModel);
      },
      disabledIfFormInvalid: true,
    },
  ];
}
