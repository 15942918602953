import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListPageComponent } from './list/list-page.component';
import { ViewPageComponent } from './view/view-page.component';
import { CreatePageComponent } from './create/create-page.component';
import { ExportedFilesPageComponent } from './exported-files/exported-files-page.component';

export const routes: Routes = [
  {
    path: 'list',
    component: ListPageComponent,
    data: {
      caption: 'List',
    },
  },
  {
    path: 'create',
    component: CreatePageComponent,
    data: {
      caption: 'Create',
    },
  },
  {
    path: 'view/:configName',
    component: ViewPageComponent,
    data: {
      caption: 'View',
    },
  },
  {
    path: ':sessionId',
    component: ExportedFilesPageComponent,
    data: {
      caption: 'Exported Files',
    },
  },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExportsRoutingModule {}
