<div class="form-body-desktop">
  <h3>Import Configuration</h3>
  <p>Upload JSON to automatically fill out configuration settings</p>

  <div style="display: inline">
    <button mat-raised-button (click)="_bundleFile.click()">
      Browse File
    </button>
    <span *ngIf="fileName"> {{ fileName }}</span>
  </div>

  <div fxLayoutGap="10px" fxLayoutAlign="end" style="margin-top: 20px">
    <button mat-raised-button color="primary" (click)="dialogRef.close(uploadedConfig)">Continue</button>
    <button mat-stroked-button color="primary" (click)="dialogRef.close(false)">Close</button>
  </div>
</div>

<!--Hidden input-->
<form>
  <input id="bundleFile" #_bundleFile (change)="addFile($event)" type="file" hidden />
</form>
