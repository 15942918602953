import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { IConfig } from 'src/app/core/interfaces/config';

@Injectable({ providedIn: 'root' })
export class EditFormService {
  private _formModel: BehaviorSubject<IConfig> = new BehaviorSubject<IConfig>(null);
  public formModel: Observable<IConfig> = this._formModel.asObservable();

  private _adapter: ReplaySubject<any> = new ReplaySubject<any>(null);
  public adapter: Observable<any> = this._adapter.asObservable();

  get formModelData() {
    return this._formModel.getValue();
  }

  changeFormModel(data: IConfig) {
    this._formModel.next(data);
  }

  changeAdapterClass() {
    this._adapter.next(null);
  }
}
