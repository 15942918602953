<mat-horizontal-stepper #stepper>
  <mat-step label="Configuration Settings">
    <ng-template matStepContent>
      <app-settings [formModel]="data" [metadata]="metadata" mode="edit" [isConfigExist]="isConfigExist"
        (nextClick)="stepper.next()" (previousClick)="stepper.previous()"></app-settings>
    </ng-template>
  </mat-step>
  <mat-step label="Authorization Types">
    <ng-template matStepContent>
      <app-adapter adapterType="auth" [formModel]="data" [metadata]="metadata" [isConfigExist]="isConfigExist"
        mode="edit" (nextClick)="stepper.next()" (previousClick)="stepper.previous()"></app-adapter>
    </ng-template>
  </mat-step>
  <mat-step label="Fetch">
    <ng-template matStepContent>
      <app-adapter adapterType="fetch" [formModel]="data" [metadata]="metadata" [isConfigExist]="isConfigExist"
        mode="edit" (nextClick)="stepper.next()" (previousClick)="stepper.previous()">
      </app-adapter>
    </ng-template>
  </mat-step>
  <mat-step label="Storage Location">
    <ng-template matStepContent>
      <app-adapter adapterType="storage" [formModel]="data" [metadata]="metadata" [isConfigExist]="isConfigExist"
        mode="edit" (nextClick)="stepper.next()" (previousClick)="stepper.previous()">
      </app-adapter>
    </ng-template>
  </mat-step>
  <mat-step label="Review">
    <ng-template matStepContent>
      <div class="review-step">
        <h3>Configuration Settings</h3>
        <app-settings [formModel]="data" [metadata]="metadata" mode="view"></app-settings>
        <h3>Authorization Types</h3>
        <app-adapter adapterType="auth" [formModel]="data" [metadata]="metadata" mode="view"></app-adapter>
        <h3>Fetch</h3>
        <app-adapter adapterType="fetch" [formModel]="data" [metadata]="metadata" mode="view"></app-adapter>
        <h3>Storage Location</h3>
        <app-adapter adapterType="storage" [formModel]="data" [metadata]="metadata" mode="view"></app-adapter>
      </div>
      <div fxLayoutAlign="end" fxLayoutGap="10px">
        <button mat-stroked-button color="primary" (click)="stepper.previous()">
          Previous
        </button>
        <button mat-raised-button color="primary" (click)="configureClick()">
          Configure
        </button>
      </div>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>